<template>
  <div>
    <v-row>
      <v-col
        v-if="showViewAsCustomer"
        cols="12"
        sm="4"
        lg="3"
        class="mt-2"
      >
        <ViewAsCustomer />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <v-menu
          ref="dateMenu"
          v-model="dateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="dateRangeText(dates)"
              label="Month range"
              prepend-icon="mdi-calendar-range"
              readonly
              single-line
              hide-details
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="dates"
            range
            type="month"
            :min="minMonth.local().format()"
            :max="currentMonth"
            show-current
          />
        </v-menu>
      </v-col>
      <v-col
        cols="6"
        lg="2"
      >
        <v-select
          v-model="fileType"
          dense
          hide-details
          :items="getFileTypes()"
          outlined
          label="File Type"
          class="mt-2 input-max-width"
          style="display: inline-block;"
          color="primary"
        />
      </v-col>
      <v-col
        cols="6"
        lg="2"
      >
        <v-autocomplete
          v-model="specialtyName"
          dense
          hide-details
          :items="getSpecialtyNames()"
          item-text="specialtyName"
          item-value="specialtyName"
          :search-input.sync="specialtyNameSearch"
          clearable
          outlined
          label="Specialty Name"
          class="mt-2"
          color="primary"
        />
      </v-col>
      <v-col
        cols="6"
        lg="2"
      >
        <v-select
          v-model="criteria"
          dense
          hide-details
          :items="criteriaItems"
          outlined
          label="Criteria"
          class="mt-2 input-max-width"
          style="display: inline-block;"
          color="primary"
        />
      </v-col>
    </v-row>
    <v-divider class="mb-5 mt-2" />
    <span
      v-if="fileType !== '' && specialtyName !== ''"
      class="text-h5 pa-2 d-flex align-center justify-center best-day-title"
    >
      {{ fileType }} - {{ specialtyName }}
    </span>
    <v-card
      v-if="results && !resultsLoading"
      class="mt-10"
    >
      <v-row>
        <v-col
          cols="12"
          md="6"
          lg="6"
        >
          <horizontal-barChart
            :chart-data="setChartData()"
            :options="getChartOptions(data1)"
            :height="500"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="6"
        >
          <horizontal-barChart
            :chart-data="setChartDataComparison()"
            :options="getChartOptions(data2)"
            :height="500"
          />
        </v-col>
      </v-row>
    </v-card>
    <v-skeleton-loader
      v-else-if="resultsLoading"
      type="image@2"
    />
  </div>
</template>

<script>
import {
  inputMonthFormat,
  inputDateFormat,
  displayMonthFormat
} from '@/shared/constants'
import mutationTypes from '@/store/mutation-types'
import { mapGetters } from 'vuex'
import { dashboardMixin } from '@/shared/mixins/mailing'
import { mailingService } from '@/shared/services'
import claims from '@/shared/models/auth/claims'
import moment from 'moment'
export default {
  name: 'BestDayReport',

  components: {
    HorizontalBarChart: () => import('@/views/pages/components/charts/HorizontalBarChart'),
    ViewAsCustomer: () => import('@/views/pages/components/user-state/ViewAsCustomer')
  },

  mixins: [dashboardMixin],

  data () {
    return {
      results: [],
      resultsComparison: [],
      data1: 'My Data',
      data2: 'MMS',
      append: '%',
      fileType: '',
      specialtyName: '',
      resultsLoading: false,
      criteria: 'Open',
      criteriaItems: [
        { text: 'Open', Value: 'O' },
        { text: 'Received', Value: 'R' }
      ],
      fileTypeOptions: [],
      chartData: {},
      chartLabels: [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday'
      ],
      dateMenu: false,
      dates: [
        moment().subtract(1, 'month').local().format(inputMonthFormat),
        moment().local().format(inputMonthFormat)
      ],
      currentMonth: moment().local().format(),
      specialtyNameSearch: '',
      unsubscribe: null
    }
  },

  computed: {
    ...mapGetters('auth', ['hasClaimKV']),
    showViewAsCustomer () {
      return this.hasClaimKV(claims.MMS_TEBT)
    },
    minMonth () {
      return moment().subtract(24, 'months')
    }
  },

  watch: {
    dates (newValue, oldValue) {
      if (newValue.length === 2) {
        this.getBestDayReport()
      }
    },
    criteria (newValue, oldValue) {
      this.getBestDayReport()
    }
  },

  mounted () {
    this.unsubscribe = this.$store.subscribe(mutation => {
      let listeners = [
        mutationTypes.SET_VIEW_AS_CUSTOMER
      ]
      if (listeners.includes(mutation.type)) {
        this.getBestDayReport()
      }
    })
  },

  created () {
    this.getBestDayReport()
  },

  destroyed () {
    this.unsubscribe()
  },

  methods: {
    async getBestDayReport () {
      if (this.dates.length !== 2 || this.$store.getters['isSimulatedMMS']()) return
      this.results = []
      this.resultsLoading = true
      await mailingService.getBestDayReport({
        customerId: this.$store.getters['simulatedCustomerNowwId'](),
        startDate: moment(this.dates[0]).startOf('month').format(inputDateFormat),
        endDate: moment(this.dates[1]).endOf('month').format(inputDateFormat),
        metric: this.criteria
      })
        .then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            this.results = resp
          }
        })
      await mailingService.getBestDayReport({
        customerId: -1,
        startDate: moment(this.dates[0]).startOf('month').format(inputDateFormat),
        endDate: moment(this.dates[1]).endOf('month').format(inputDateFormat),
        metric: this.criteria
      })
        .then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            this.resultsComparison = resp
          }
        })
        .finally(() => {
          this.resultsLoading = false
        })
    },
    dateRangeText (dates) {
      if (!dates || dates.length !== 2) return ''
      if (moment(dates[0]).isSame(moment(dates[1]), 'month')) return moment(dates[0]).format(displayMonthFormat)
      return dates.map(x => moment(x).format(displayMonthFormat)).join(' - ')
    },
    getFileTypes () {
      var fileTypes = this.results.map(x => x.fileType)
      return fileTypes
    },
    getSpecialtyNames () {
      let specialtyNames = this.results.filter(x => x.fileType === this.fileType).map(x => x.specialtyName)
      return specialtyNames
    },
    setChartData () {
      var chartData = {
        labels: [],
        datasets: []
      }
      if (!this.results || this.specialtyNameSearch === '') return {}
      var specialtyData = this.results.filter(x => (x.fileType === this.fileType && x.specialtyName === this.specialtyNameSearch)).map((x) =>
        ({ eventDay: x.eventDay, eventDayName: x.eventDayName, eventDayCount: x.eventDayCount, edcp: x.edcp.toFixed(2), specialtyName: x.specialtyName })).sort((a, b) => a.eventDay - b.eventDay)

      var bulkData = specialtyData.map((x) => ({ edcp: x.edcp, dayName: x.eventDayName }))
      var result = [...bulkData.reduce((r, o) => {
        const key = o.dayName

        const item = r.get(key) || Object.assign({}, o, {
          edcp: 0
        })
        item.edcp += o.edcp

        return r.set(key, item)
      }, new Map()).values()]

      chartData.labels = result.map(x => x.dayName)

      var dataset = {
        label: this.specialtyNameSearch || '',
        backgroundColor: '#6d2c8a',
        data: []
      }

      dataset.data = result.map(x => x.edcp)
      chartData.datasets.push(dataset)
      return chartData
    },
    setChartDataComparison () {
      var chartData = {
        labels: [],
        datasets: []
      }
      if (!this.resultsComparison || this.specialtyNameSearch === '') return {}
      var specialtyData = this.resultsComparison.filter(x => (x.fileType === this.fileType && x.specialtyName === this.specialtyNameSearch)).map((x) =>
        ({ eventDay: x.eventDay, eventDayName: x.eventDayName, eventDayCount: x.eventDayCount, edcp: x.edcp.toFixed(2), specialtyName: x.specialtyName })).sort((a, b) => a.eventDay - b.eventDay)

      var bulkData = specialtyData.map((x) => ({ edcp: x.edcp, dayName: x.eventDayName }))
      var result = [...bulkData.reduce((r, o) => {
        const key = o.dayName

        const item = r.get(key) || Object.assign({}, o, {
          edcp: 0
        })
        item.edcp += o.edcp

        return r.set(key, item)
      }, new Map()).values()]

      chartData.labels = result.map(x => x.dayName)

      var dataset = {
        label: this.specialtyNameSearch || '',
        backgroundColor: '#ff7235',
        data: []
      }

      dataset.data = result.map(x => x.edcp)
      chartData.datasets.push(dataset)
      return chartData
    },
    getChartOptions (title) {
      return {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
          fontStyle: 'bold',
          fontSize: 18,
          text: title === 'My Data' ? `My ${this.criteria} Data` : 'MMS Benchmark'
        },
        legend: {
          labels: {
            fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
            fontSize: 14
          }
        },
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
              fontStyle: 'bold',
              fontSize: 14,
              labelString: 'Day of the week'
            },
            ticks: {
              suggestedMin: 0,
              beginAtZero: true,
              fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
              callback: function (value, index, values) {
                return `${value}`
              }
            }
          }],
          xAxes: [{
            scaleLabel: {
              display: true,
              fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
              fontStyle: 'bold',
              fontSize: 14,
              labelString: `${this.criteria} percentage`
            },
            ticks: {
              fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
              callback: function (value, index, values) {
                return `${value}%`
              }
            }
          }]
        },
        tooltips: {
          enabled: true,
          callbacks: {
            label: (tooltipItem, data) => {
              return `${tooltipItem.value}%`
            }
          }
        }
      }
    }
  }
}
</script>
<style>
  .best-day-title {
    font-family: Century Gothic,CenturyGothic,AppleGothic,Roboto,sans-serif !important;
    text-align: center;
    font-size: 1.5rem;
    line-height: 2rem;
    letter-spacing: normal;
    font-weight: 700;
  }
</style>
